import {
  Vue, Options
} from 'vue-class-component'
import * as bootstrap from 'bootstrap'
import router from '@/router/index'
import Header from '@/components/Header.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import CurrencyInputDisable from '@/components/CurrencyInputDisable.vue'
import swal from 'sweetalert'
import Utils from '@/utils/utils'
import { customerFactory } from '@/http/customer'
import { masterDataFactory } from '@/http/master-data'
import { usFactory } from '@/http/uang-sekolah'
import constants from '@/constants/constants'
import token from '@/constants/token'

declare var snap: any

@Options({
  components: {
    Header, CurrencyInput, CurrencyInputDisable
  },
  data() {
    return {
      currentSort:'name',
      currentSortDir:'asc',
      userPayments: [],
      userBills: [],
      userOrders: [],
      stats: {
        total_bill: 0,
        total_paid: 0
      },
      user: {
        name: '',
        class_name: '',
        parents_father: '',
        parents_mother: '',
        user_profile_no_hp: '',
        student_profile_nis: ''
      },
      school: {
        name: ''
      },
      statuses: [],
      schoolYears: [],
      months: [],
      convenienceFee: [],
      data_master: {
        school_years: "1",
        school: "1",
        months: "1",
        convenience_fee: "1"
      },
      param: {
        school_year_id: 0,
        order_at: 'deadline_date'
      },
      school_year_name: '',
      data: {
        bill_name: '',
        school_year_name: '',
        month_name: '',
        fine_rules: {
          value: 0,
          type: 'nominal',
          formula: 'flat'
        },
        total_amount: 0,
        total_payment: 0,
        late_charge: 0,
        details: [],
        deadline_date: '',
        due_date: '',
        description: ''
      },
      totalAmount: 0,
      totalSelecttedAmount: 0,
      totalPaid: 0,
      billCount: 0,
      billIds: [],
      allSelectedBill: false,
      path: process.env.VUE_APP_STORAGE_PATH,
      instance: '',
      subdomain: token.user.subdomain,
      token_order_id: '',
      constants: constants,
      loadingBulk: false,
      loading: [],
      loadingYear: false,
      setting: [],
      convenience_id: [],
      paymentMethod: true,
      payment: {
        amount: 0,
        convenience_fee: 0,
        merchant_fee: 0,
        total_discount: 0,
        ppn: 0,
        total_amount: 0
      },
      tempCheckout: {
        ids: [],
        type: ''
      },
      xenditModal: null,
      xenditInvoice: "",
      discountSetting: "",
      imagepath: null,
      repayUserBillIDs: null
    }
  },
  methods: {
    inArray: function(id:any) {
      if (this.billIds.length > 0 && id != '') {
        var exist = this.billIds.indexOf(id)
        if (exist > -1) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    tableDataClass(due_date:any, deadline_date:any, status: any) {
      var duedate = new Date(due_date)
      var datenow = new Date()
      datenow.setHours(0,0,0,0)
      var deadlinedate = new Date(deadline_date)
      var d1 = deadlinedate.getTime()
      var d2 = datenow.getTime()
      var d3 = duedate.getTime()
      var normal, almostExpired, expired = false
      if (status == constants.BILL_PENDING || status == constants.BILL_UNPAID) {
        if (d2 >= d1) {
          expired = true
        } else if (d2 > d3 && d3 < d1) {
          almostExpired = true
        } else if (d2 < d3) {
          normal = true
        }
      } else {
        normal = true
      }
      return {
        '': normal,
        'table-warning': almostExpired,
        'table-danger': expired,
      }
    },
    onImageLoadFailure: function(event:any) {
      event.target.src = '/img/photos/default-avatar.png'
    },
    checkAllBill: function() {
      this.billIds = []
      if (!this.allSelectedBill) {
          this.userBills.forEach((detail:any) => {
            if ((detail.status == constants.BILL_PENDING || detail.status == constants.BILL_UNPAID) && detail.spp_order_ids == undefined || this.checkExpired(detail.spp_order_ids)) {
              this.billIds.push(detail.id)
            }
          })
      }
    },
    checkAllBillBySetting: function() {
      this.billIds = []
      if (!this.allSelectedBill) {
          this.userBills.forEach((detail:any) => {
            if ((detail.status == constants.BILL_PENDING || detail.status == constants.BILL_UNPAID) && detail.spp_order_ids == undefined || this.checkExpired(detail.spp_order_ids)) {
              if (detail.discount_setting_id === this.setting['UANGSEKOLAH_DISCOUNT_SETTING']) {
                this.billIds.push(detail.id)
              }
            }
          })
      }
    },
    sort:function(s:any) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc'
      }
      this.currentSort = s
    },
    getMonth: function(date:any) {
      if (date != '' && date != undefined) {
        const d = date.split("-")
        const month  = d[2]
        return this.months[month].id
      } else {
        return ''
      }
    },
    beautifyDate: function(date:any) {
      return Utils.beautifyDate(date)
    },
    toLocaleString: function(val:any) {
      return Utils.toLocaleString(val)
    },
    checkExpired: function(spp_order_ids:any) {
      if (spp_order_ids != undefined) {
        var result = this.userOrders.find((x:any) => x.spp_order_id === spp_order_ids[0])
        if (result != undefined) {
          var expireddate = new Date(result.expired_date)
          var datenow = new Date()
          datenow.setHours(0,0,0,0)
          var d1 = expireddate.getTime()
          var d2 = datenow.getTime()
          if (d1 < d2) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return true
      }
    },
    detailBill: function(id:any) {
      Promise.all([
        customerFactory.detailBill(id)
      ]).then(results => {
        if (results[0].error == false) {
          var result = results[0].data.bill_user
          var data = {
            school_year_name: this.school_year_name,
            bill_name: result.bill.name,
            month_name: this.getMonth(result.id),
            deadline_date: Utils.beautifyDate(result.deadline_date),
            due_date: Utils.beautifyDate(result.due_date),
            total_amount: result.total_amount,
            total_payment: result.total_amount + result.late_charge + result.ppn,
            late_charge: result.late_charge,
            ppn: result.ppn,
            description: result.bill.description,
            fine_rules: result.bill.fine_rules,
            details: result.details
          }

          // Remove bill detail which has 0 amount
          for (var i =0; i < data.details.length; i++) {
            if (!data.details[i].amount) {
              data.details.splice(i, 1)
              i--
            }
          }

          if (result.bill.fine_rules.length == 0) {
            var fine_rules = {
              value: 0,
              type: 'nominal',
              formula: 'flat'
            }
            data.fine_rules = fine_rules
          }
          this.data = data
          this.showModal()
        } else  if (results[0].response.data.error == true) {
          var errorMessage = results[0].response.data.messages
          swal(errorMessage, "", "error")
        }
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    resetTempCheckout: function () {
      this.tempCheckout.ids = []
      this.tempCheckout.type = ''
    },
    setTempCheckout: function (ids:any, type:any) {
      this.tempCheckout.ids = ids
      this.tempCheckout.type = type
    },
    resetConvenienceFee: function () {
      this.paymentMethod = true
      this.convenience_id = 0
      var payment = {
        amount: 0,
        convenience_fee: 0,
        merchant_fee: 0,
        total_discount: 0,
        ppn: 0,
        total_amount: 0
      }
      this.payment = payment
    },
    checkOutConvenience: function () {

      this.loadingBulk = true
      var data = {
        convenience_id: this.convenience_id,
        spp_user_bill_ids: this.tempCheckout.ids
      }
      Promise.all([
        customerFactory.checkoutOrder(data)
      ]).then(results => {
        if (results[0].error == false) {
          var us_order_id = results[0].data.order.us_order_id
          var order_id = results[0].data.order.id
          this.handlePayment(us_order_id, order_id)
        } else  if (results[0].response.data.error == true) {
          var errorMessage = results[0].response.data.messages
          swal(errorMessage, "", "error")
        }
        this.loadingBulk = false
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        this.loadingBulk = false
        console.log(e)
      }).finally(() => {
        this.loadingBulk = false
      })
    },
    bulkCheckout: function(type:any) {
      this.resetTempCheckout()
      this.setTempCheckout(this.billIds, 'bulk')
      if (this.setting['ENABLE_CONVENIENCE_FEE'] == 'true' && type !== 'pay') {
        this.resetConvenienceFee()
        this.showModal('convenience')
      } else {
        this.loadingBulk = true
        swal({
          title: "Apakah anda yakin?",
          text: "Anda akan membayar tagihan terpilih.",
          icon: "warning",
          buttons: {
            cancel: true,
            confirm: true
          },
          dangerMode: true,
        })
        .then((isConfirm) => {
          if (isConfirm) {
            var data = {
              convenience_id: 0,
              spp_user_bill_ids: this.tempCheckout.ids
            }
            Promise.all([
              customerFactory.checkoutOrder(data)
            ]).then(results => {
              if (results[0]) {
                var us_order_id = results[0].data.order.us_order_id
                var order_id = results[0].data.order.id
                this.handlePayment(us_order_id, order_id)
              }
            })
          } else {
            this.loadingBulk = false
          }
        })
        .catch(() => {
          this.loadingBulk = false
        }).finally(() => {
          this.loadingBulk = false
        })
      }
    },
    oneYearCheckout: function(type:any) {
      this.checkAllBillBySetting()
      this.resetTempCheckout()
      this.setTempCheckout(this.billIds, 'bulk')
      if (this.setting['ENABLE_CONVENIENCE_FEE'] == 'true' && type !== 'pay') {
        this.resetConvenienceFee()
        this.showModal('convenience')
      } else {
        this.loadingBulk = true
        swal({
          title: "Apakah anda yakin?",
          text: "Anda akan membayar tagihan terpilih.",
          icon: "warning",
          buttons: {
            cancel: true,
            confirm: true
          },
          dangerMode: true,
        })
        .then((isConfirm) => {
          if (isConfirm) {
            var data = {
              convenience_id: 0,
              spp_user_bill_ids: this.tempCheckout.ids
            }
            Promise.all([
              customerFactory.checkoutOrder(data)
            ]).then(results => {
              if (results[0].error == false) {
                var us_order_id = results[0].data.order.us_order_id
                var order_id = results[0].data.order.id
                this.handlePayment(us_order_id, order_id)
              } else  if (results[0].response.data.error == true) {
                var errorMessage = results[0].response.data.messages
                swal(errorMessage, "", "error")
              }
            }).catch((e) => {
              swal("Gagal melakukan request", "", "error")
              console.log(e)
            })
          } else {
            this.loadingYear = false
          }
        })
        .catch(() => {
          this.loadingYear = false
        }).finally(() => {
          this.loadingYear = false
        })
      }
    },
    checkout: function(id:any, order_id:any, type:any) {

      var ids : any = []
      ids.push(id)
      this.resetTempCheckout()
      this.setTempCheckout(ids, 'single')
      if (this.setting['ENABLE_CONVENIENCE_FEE'] == 'true' && type !== 'pay') {
        this.resetConvenienceFee()
        this.showModal('convenience')
      } else {
        if (order_id != undefined && !this.checkExpired(order_id)) {
          this.hideModal('convenience')
          router.push({path: '/user/invoice', query: {id: order_id[0]}})
        } else {
          this.loading[id] = true
          var data = {
            convenience_id: 0,
            spp_user_bill_ids: this.tempCheckout.ids
          }
          Promise.all([
            customerFactory.checkoutOrder(data)
          ]).then(results => {
            if (results[0].error == false) {
              var us_order_id = results[0].data.order.us_order_id
              var order_id = results[0].data.order.id
              this.handlePayment(us_order_id, order_id)
            } else  if (results[0].response.data.error == true) {
              var errorMessage = results[0].response.data.messages
              swal(errorMessage, "", "error")
              this.loading[id] = false
            }
          }).catch((e) => {
            swal("Gagal melakukan request", "", "error")
            console.log(e)
            this.loading[id] = false
          }).finally(() => {
            this.loading[id] = false
          })
        }
      }
    },
    addSnapJS () {
      var uangsekolah = document.createElement('script')
      var instance = String(localStorage.getItem('instance'))
      uangsekolah.setAttribute('src', localStorage.getItem('base_url') + '/' + instance + '/us/snap.js')
      document.head.appendChild(uangsekolah)
    },
    handlePayment: function(us_order_id:any, order_id:any) {

      Promise.all([
        usFactory.getUSToken(us_order_id)
      ]).then(results => {
        var token_order_id = results[0].data.us_order.token
        var us_order = results[0].data.us_order
        var merchant = us_order.merchant

        if (merchant.type == 'midtrans') {
          if (typeof snap === 'undefined') {
            swal("Halaman Belum siap", "", "error")
            return
          }

          snap.pay(token_order_id, {
            uiMode: "qr",
            gopayMode: "qr",
            onSuccess: function(){
              this.hideModal('convenience')
              router.push({path: '/user/invoice', query: {id: order_id}})
              return false
            },
            onPending: function(){
              this.hideModal('convenience')
              router.push({path: '/user/invoice', query: {id: order_id}})
              return false
            },
            onError: function(){
              return false
            },
            onClose: function(){
              this.hideModal('convenience')
              router.push({path: '/user/invoice', query: {id: order_id}})
              return false
            }
          })

        } else if (merchant.type == 'xendit') {
          this.xenditInvoice = us_order.link
          this.xenditModal.show()
        }


      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      }).finally(() => {
        this.loadData()
        return false
      })
      return false
    },
    handleConvenienceFee: function (value:any) {
      this.convenience_id = value
      var param = {
        convenience_id: value,
        spp_user_bill_ids: this.tempCheckout.ids
      }
      Promise.resolve(customerFactory.calcConvenienceFee(param))
      .then(result => {
        var payment = {
          convenience_fee: result.data.convenience_fee,
          merchant_fee: result.data.merchant_fee,
          amount: result.data.total_amount,
          total_discount: '-' + result.data.total_discount,
          ppn: result.data.ppn,
          total_amount: result.data.total_payment
        }
        this.payment = payment
        this.paymentMethod = false
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
      return false
    },
    invoice: function(order_id:any) {
      if (order_id != undefined) {
        router.push({path: '/user/invoice', query: {id: order_id[0]}})
      }
    },
    loadData: function() {
      Promise.all([
        customerFactory.bill(this.param)
      ]).then(results => {
        var userData = results[0].data.user
        var user = {
          name: ('name' in userData) ? userData.name : '',
          class_name: ('class' in userData) ? ('name' in userData.class) ? userData.class.name : '' : '',
          parent_father: ('parents' in userData) ? ('father' in userData.parents) ? userData.parents.father : '' : '',
          parent_mother: ('parents' in userData) ? ('mother' in userData.parents) ? userData.parents.mother : '' : '',
          user_profile_no_hp: ('user_profile' in userData) ? ('no_hp' in userData.user_profile) ? userData.user_profile.no_hp : '' : '',
          student_profile_nis: ('student_profile' in userData) ? ('nis' in userData.student_profile) ? userData.student_profile.nis : '' : ''
        }
        this.user = user
        this.userBills = results[0].data.user_bills
        this.userBills.forEach((value:any) => {
          value.total_payment = value.total_amount + value.late_charge
        })
        this.stats = results[0].data.stats
        this.statuses = results[0].data.statuses
        this.userOrders = results[0].data.user_orders
        this.billCount = this.userOrders.length
        var path = this.path + this.subdomain + '/student_photos/' + this.user.student_profile_nis + '.jpg'
        this.imagepath = path
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    loadDataMaster: function() {
      var setting_data = {
        title: 'ENABLE_CONVENIENCE_FEE'
      }
      var setting_data_2 = {
        title: 'UANGSEKOLAH_DISCOUNT_SETTING'
      }
      return Promise.all([
        masterDataFactory.master_datas(this.data_master),
        masterDataFactory.setting_data(setting_data),
        masterDataFactory.setting_data(setting_data_2)
      ]).then(results => {
        this.schoolYears = results[0].data.school_years
        this.months = results[0].data.months
        this.school = results[0].data.school
        this.convenienceFee = results[0].data.convenience_fee
        this.schoolYears.forEach((school_year:any) => {
          if (school_year.status == "Active") {
            this.param.school_year_id = school_year.school_year_id
            this.school_year_name = school_year.name
          }
        })
        var setting = results[1].data.setting
        var value = (setting != null) ? setting.value : ''
        var title = (setting != null) ? setting.title : ''
        this.setting[title] = value
        var setting = results[2].data.setting
        var value = (setting != null) ? setting.value : ''
        var title = (setting != null) ? setting.title : ''
        this.setting[title] = value
        this.loadData()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    showModal: function(type:any) {
      if (type == 'convenience') {
        this.myModalConvenience.show()
      } else {
        this.myModal.show()
      }
    },
    hideModal: function(type:any) {
      if (type == 'convenience') {
        this.myModalConvenience.hide()
      } else {
        this.myModal.hide()
      }
    }
  },
  computed: {
    totalSelectedAmount: function () {
      var totalSelectedAmount = 0
      this.userBills.forEach((detail:any) => {
        if (this.billIds.indexOf(detail.id) != -1) {
          totalSelectedAmount += detail.total_payment
        }
      })
      return this.toLocaleString(totalSelectedAmount)
    },
    config() {
      return {
        currency: 'IDR',
        precision: 0,
        distractionFree: false,
        valueAsInteger: true,
      }
    }
  },
  async mounted () {
    await this.loadDataMaster()
    await this.addSnapJS()

    var myModal = document.getElementById('billDetailModal')
    if (myModal != null) {
      this.myModal = new bootstrap.Modal(myModal)
    }
    var xenditModal = document.getElementById('xenditModal')
    if (xenditModal != null) {
      this.xenditModal = new bootstrap.Modal(xenditModal)
    }
    var myModalConvenience = document.getElementById('convenienceFeeModal')
    if (myModalConvenience != null) {
      this.myModalConvenience = new bootstrap.Modal(myModalConvenience)
    }

    if (this.$route.query.repay_user_bill_ids) {
      this.billIds = this.$route.query.repay_user_bill_ids
      this.bulkCheckout()
    }
  }

})

export default class UserBill extends Vue {}
